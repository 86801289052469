// paper & background
$paper: #ffffff;

// primary
$primaryLight: #fbfafa;
$primaryMain: #000000;
$primaryDark: #000000;
$primary200: #90caf9;
$primary800: #1565c0;

// secondary
$secondaryLight: #eef2f6;
$secondaryMain: #212022;
$secondaryDark: #19191a;
$secondary200: #b39ddb;
$secondary800: #000000;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;


// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #000000;

// grey
$grey50: #fafafa;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #2c2c2c;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #eef2f6;
$darkPrimaryMain: #000000;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

//register table colors
$boxshadow: #e3e3e3;
$statsbox: #ffff;
$statsbox1: #faad14;
$viewbtn: #0c66e4;
$fileupload: #dcdfe4;
$navbartxt: #172b4d;
$statusColor: #76839a;
$modalcontent: #ffffff;
$modalcontent1: #000000d1;
$modalresult: #7c7d7f;
$closeIcon: #707070;
$undelinecolor: #d9d9d9;
$cancelbtn: #0000003b;
$gradientColor: #7070702e;
$attachbtn: #ffff;
$cmntxt:#000000;
$cmntxt1:#818181;
$borderCrl:#e6e6e6;
$personImg:#E7E7E7;
$patientdetail:#FDFDFD;
$personCaps:#BFDCFF;
$personCaps1:#1C5AFA;
$backbutton:#0040ff;
$boxwhite:#fff;
$pdftxt:#757575;
$cancelbtnCrl:#FF4D4F;
$cancelbtnPhoto:#f24539;
$headInputSecCrl:#0C66E4;
$statusboxCrl:#009444 ;
$subheadCrl:#626F86;
$backgroundTxt:#e0f0ff;
$borderTxt:#007bff;
$bordeBottmcrl:#e0e0e0;
$uploadborder:#DCDFE4;
$dragspan:#172B4D;
$statuboxCrl:#faad140d;
$statusboxcrl1:#0094440d;
$lightgrayNew:#D3D3D3;
$checkboxblue:#0000FF;
$filterCrl:#0000001A;
$primarywhite:#FFFFFF;
$loginCrl:#F7F8F9;
$loginCrlnew:#fff;
$loginSubtxt:#6B778C;
$errorCrl:#FF0000;
$updateWhite:#FFFFFF;


$checkbox:#0c66e4;
$tablecllcrl:#dbdfe6;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;

  //register page
  boxshadow: $boxshadow;
  statsbox: $statsbox;
  statsbox1: $statsbox1;
  viewbtn: $viewbtn;
  fileupload: $fileupload;
  navbartxt: $navbartxt;
  statusColor: $statusColor;
  modalcontent: $modalcontent;
  modalcontent1: $modalcontent1;
  modalresult: $modalresult;
  closeIcon: $closeIcon;
  undelinecolor: $undelinecolor;
  cancelbtn: $cancelbtn;
  gradientColor: $gradientColor;
  attachbtn: $attachbtn;
  cmntxt:$cmntxt;
  cmntxt1:$cmntxt1;
  borderCrl:$borderCrl;
  personImg:$personImg;
  patientdetail:$patientdetail;
  personCaps:$personCaps;
  personCaps1:$personCaps1;
  backbutton:$backbutton;
  boxwhite:$boxwhite;
  pdftxt:$pdftxt;
  cancelbtnCrl:$cancelbtnCrl;
  cancelbtnPhoto:$cancelbtnPhoto;
  headInputSecCrl:$headInputSecCrl;
  statusboxCrl:$statusboxCrl; 
  subheadCrl:$subheadCrl; 
  backgroundTxt:$backgroundTxt;
  borderTxt:$borderTxt;
  bordeBottmcrl:$bordeBottmcrl;
  uploadborder:$uploadborder;
  statuboxCrl:$statuboxCrl;
  statusboxcrl1:$statusboxcrl1;
  lightgrayNew:$lightgrayNew;
  checkboxblue:$checkboxblue;
  filterCrl:$filterCrl;
  primarywhite:$primarywhite;
  loginCrl:$loginCrl;
  loginCrlnew:$loginCrlnew;
  loginSubtxt:$loginSubtxt;
  errorCrl:$errorCrl;
  updateWhite:$updateWhite;
  checkbox:$checkbox;
  tablecllcrl:$tablecllcrl;
}
